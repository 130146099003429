<template>
    <div style="height:100%">
        <!-- 主页面上传 -->
        <div v-show="!showImportTable" class="import-page">
            <div class="title-text">
                <div class="title-icon">1</div>
                <div class="title-tip">准备客户</div>
            </div>
            <div class="info-content">
                <div class="info-tip">
                    导入的客户个数不能超过
                    <font style="text-decoration: underline;">10000个</font>，所有允许导入的客户字段请参考模板；客户字段不符合规则，将不予以导入。
                </div>
                <div class="show-more" @click="showMore = !showMore">
                    <div class="show-more-tip">更多字段导入规则</div>
                    <div class="show-more-icon">
                        <i :class="showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
                    </div>
                </div>
                <el-collapse-transition>
                    <div v-show="showMore" class="more-contentinfo">
                        <div>1)"电话" 字段为必填项；</div>
                        <div>2)"性别" 字段内容为未知（空为未知）、男、女；</div>
                        <div>3)"年龄" 字段为大于0、小于127的数值；</div>
                        <div>4)"居住地" 字段只能是城市名称，例如：杭州市；</div>
                        <div>5)"有车" 字段内容为未知（空为未知）、有、无；</div>
                        <div>6)"有房" 字段内容为未知（空为未知）、有、无；</div>
                        <div>7)"有社保" 字段内容为未知（空为未知）、有、无；</div>
                        <div>8)"有公积金" 字段内容为未知（空为未知）、有、无；</div>
                        <div>9)"有保单" 字段内容为未知（空为未知）、有、无；</div>
                    </div>
                </el-collapse-transition>
                <div class="download" @click="downloadModel">
                    <img width="30" src="@/assets/common/download.png" alt />
                    <div class>下载导入模板</div>
                </div>
            </div>
            <div class="title-text">
                <div class="title-icon">2</div>
                <div class="title-tip">上传数据文件</div>
            </div>
            <div class="info-content">
                <div class="info-tip">目前支持的文件类型为 *xlsx。</div>
                <!-- <el-button style="margin-top:10px" size="small">上传文件</el-button> -->
                <el-upload
                    class="uploadEcxel"
                    :before-upload="beforeUpload"
                    :action="UploadUrl()"
                    :headers="myHeaders"
                    :on-change="handleChange"
                    :on-success="handleSuccess"
                    :before-remove="beforeRemove"
                >
                    <el-button size="small">上传文件</el-button>
                </el-upload>
            </div>
            <el-button
                v-if="importClueData.batchNo"
                style="margin-top:10px;margin-left:30px;width:80px"
                size="small"
                type="primary"
                @click="toTablePage"
            >下一步</el-button>
        </div>
        <!-- 组件页面表单导入 -->
        <import-table v-show="showImportTable" ref="importTable" @toIndex="toIndex" />
    </div>
</template>

<script>
let token = sessionStorage.getItem('token');
export default {
    components: {
        importTable: () => import('./components/importTable'),
    },
    data() {
        return {
            showMore: false,
            showImportTable: false,
            devEnviron: '',
            myHeaders: { Authorization: token },
            importClueData: {},
        };
    },
    created() {
        if (process.env.VUE_APP_MY_PARAM == 'development') {
            this.devEnviron = 'dev';
        } else if (process.env.VUE_APP_MY_PARAM == 'production') {
            this.devEnviron = 'test';
            // this.devEnviron = "prod"
        }
    },
    methods: {
        downloadModel() {
            window.location.href =
                window.location.protocol +
                '//zdcrm.oss.hzrjj.com/' +
                this.devEnviron +
                '/public/KeHu.xlsx';
            this.$message.success('已成功下载模板');
        },
        UploadUrl() {
            return process.env.VUE_APP_URL + '/data-management/client-file';
        },
        beforeUpload(file) {
            let Xls = file.name.split('.');
            if (Xls[1] === 'xlsx') {
                return file;
            } else {
                this.$message.error('上传文件只能是 xlsx 格式!');
                return false;
            }
        },
        handleChange(file, fileList) {
            console.log(file);
            console.log(fileList);
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
        },
        beforeRemove() {
            this.importClueData = {};
        },
        handleSuccess(res) {
            if (res.code == 0) {
                this.importClueData = res.data;
            } else {
                this.$message.error(res.message);
            }
        },
        toTablePage() {
            // 这里做一下判断如果没有上传成功就进入下一页否则报个错
            this.showImportTable = true;
            this.$nextTick(() => {
                this.$refs.importTable.init(this.importClueData);
            });
        },
        toIndex() {
            this.showImportTable = false;
        },
    },
};
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    align-items: center;
}
.import-page {
    height: 100%;
    width: 100%;
    padding: 20px;
    .title-text {
        display: flex;
        align-items: center;
        .title-icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            border: 1px solid #aaa;
            color: #aaa;
            margin-right: 10px;
        }
        .title-tip {
            font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
            font-weight: 700;
            font-style: normal;
            font-size: 18px;
            color: #555555;
        }
    }
    .info-content {
        margin-left: 30px;
        margin-top: 20px;
        .info-tip {
            color: #555;
            font-size: 16px;
        }
        .show-more {
            margin-top: 10px;
            margin-bottom: 10px;
            color: #4086ec;
            font-size: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .show-more-tip {
                margin-right: 5px;
            }
        }
        .more-contentinfo {
            font-family: '微软雅黑';
            font-weight: 400;
            font-style: normal;
            color: #7f7f7f;
            margin-bottom: 10px;
        }
        .download {
            display: flex;
            align-items: center;
            cursor: pointer;
            &:last-child {
                color: #4086ec;
                font-size: 16px;
            }
        }
    }
}
.uploadEcxel {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .el-upload-list {
        width: 20%;
    }
}
/deep/ .el-upload-list__item:first-child {
    margin-top: 0px !important;
}
</style>
